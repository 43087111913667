<template>
  <g :fill="color" fill-rule="evenodd" transform="translate(-796 -196)">
    <path
      id="prefix__a"
      d="M815.556 206.636h-7.334c-.675 0-1.222-.529-1.222-1.181v-7.091h-7.333c-.675 0-1.223.529-1.223 1.181v18.91c0 .652.548 1.181 1.223 1.181h14.666c.675 0 1.223-.529 1.223-1.181v-11.819h1.222c.675 0 1.222-.529 1.222-1.181 0-.164-.034-.32-.096-.46.064.15.096.303.096.46v13c0 1.958-1.642 3.545-3.667 3.545h-14.666c-2.025 0-3.667-1.587-3.667-3.545v-18.91c0-1.958 1.642-3.545 3.667-3.545h8.555c.324 0 .635.125.864.346l8.556 8.273c.115.11.204.241.265.383zm-6.112-6.601v4.238h4.383l-4.383-4.238z"
    />
  </g>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
